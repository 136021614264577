export const configs_keys =
  'general,onboarding,accounts,payments,seo,social,styles,integrations,draft_styles,listings,pages'

export const tenant_configs_keys = 'general,onboarding,accounts,payments,integrations,listings'

export const configs_keys_for_draft =
  'general,onboarding,accounts,payments,seo,social,styles,integrations,listings,pages'

export const configs_domain_keys_for_duplicate =
  'theme,sample_data_loaded,type,sub_type,auth_type,theme,faq_url,header_text,terms_url,support_url,privacy_policy_url,website_name,web_primary_font,web_logo,how_it_works_url,home_blogs_widget_disabled,web_icon,feedback_contact_field,logo_width,logo_height,logo_object_fit,contain,app_color_primary,logo_small,logo,bg_footer_color,app_color_secondary,intro_screens,app_name,bg_header_color,bg_body_color,background_image,meta_title,facebook_pageurl,youtube_channelurl,twitter_handleurl,whatsapp_number,telegram_url,form_styles,button_styles,listing_card,home,header,body,typography,dynamic_header,checkout,dynamic_footer,page_styles,styles,price,main_buttons,location_widget,listing_share_section,review_and_rating,similar_listing_card,account_listings,home,listing_details,checkout,tag_listings,auth,blog_details,page_details,wallet,dynamic_listing_card'

export const tenant_configs_keys_for_exclude = [
  'theme',
  'logo_width',
  'logo_height',
  'logo_object_fit',
  'web_primary_font',
  'web_font_body',
  'tagline',
  'terms_url',
  'support_url',
  'website_name',
  'web_logo',
  'web_icon',
  'contain',
  'privacy_policy_url',
  'meta_title',
  'meta_description',
  'app_color_primary',
  'app_color_secondary',
  'bg_footer_color',
  'bg_header_color',
  'bg_body_color',
  'background_image',
]
